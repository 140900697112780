
export const isPixiStandAlone = false

export const basePath = isPixiStandAlone ? '/public/assets/pixi-assets/plinko-game/' : '/assets/pixi-assets/plinko-game/';
export const path = {
  imagePath: basePath + "images/",
  audioPath: basePath + "audios/",
  spriteSheetsPath: basePath + "spriteSheets/",
  fontPath: basePath + "fonts/"
}

export const config = {
  maxLines: 16,
  originalWidth: 1080,
  originalHeight: 600,
  topMargin: 720,
  rows: 8,
  pegs: {
    spacing: {
      width: 105,
      height: 95
    },
    pegsWidth: 40,
    shine: {
      animationSpeed: 1.3
    }
  },
  ball: {
    path: [0, 1, 0, 1, 0, 1, 0, 1],
    radius: 30,
    animationSpeed: 0.75,// 0.9,
    scaleForAnimation: [1.9, 1.7, 1.52, 1.376, 1.26, 1.16, 1.054, 1, 0.96],
    bounceEffect: {
      //                 8    9    10     11     12     13     14    15    16
      scaleFactorScale: [1, 0.99, 0.89, 0.80, 0.79, 0.76, 0.74, 0.72, 0.56],
      scaleFactorX: [1, 0.888, 0.78, 0.73, 0.667, 0.65, 0.59, 0.542, 0.52],
      scaleFactorY: [1.01, 0.888, 0.796, 0.72, 0.655, 0.6, 0.555, 0.517, 0.48],
    },
    ballScale:1.6,
  },
  box: {
    boxSpeed : 1.9,
    height:153,
    width:153,
    heightScale:4.5, //75
    widthScale:4.5
  },
  pinInFirstLine: 3,
  bgAssets: {
    speed: 0.06,
  },
}

export const gameDisplaySettings = {
  size: 630,
  // x: config.originalWidth / 2,
  // y: (config.originalHeight / 2) - 20
  pc: {
    x: 0,
    y: 50,
  },
  mobile: {
    x: 0,
    y: 0
  }

}
