
let device ='PC';


export function isDevice(){
    return device;
}

export function setDevice(deviceName){

    device=deviceName
}
