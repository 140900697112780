import Routes from 'routes/index'
import store from 'redux-store/store'
import { Provider } from 'react-redux'
import React, { Suspense } from 'react'
import Loader from 'components/ui-kit/Loader/index'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ErrorBoundary from 'components/ui-kit/ErrorBoundary/index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from 'react-query'

function App () {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        // five seconds to allow quick navigation around the app without refetch
        staleTime: 5 * 1000,
      },
    },
  });
  
  

  return (
    <Suspense fallback={<Loader component='page' />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <ToastContainer
              position='top-right'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme='colored'
              style={{ zIndex: 99999 }}
            />
            <Routes />
          </ErrorBoundary>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  )
}

export default App
