import axiosInstance, { microServices } from 'network/apis/index'
import { METHOD_TYPES } from 'utils/constants/index'

export const placeBetSpaceDiceGameService = (data) => {
  const { isDemoGame, ...payload } = data
  return axiosInstance(METHOD_TYPES.post, '/space-dice-game/place-bet', payload, {
    server: microServices.SERVICE_URL_1,
    headers: { 'demo-game': isDemoGame }

  })
}

export const getTopBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/space-dice-game/top-bets', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getMyBetsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/space-dice-game/my-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}
