
let dropTickSource, positiveWinSoundSource, firstDropSource
import { path } from "./settings.js"

export function loadSounds () {
  firstDropSource = PIXI.sound.Sound.from({
    url: path.audioPath + 'first-drop.wav',
    volume: 0
  })
  dropTickSource = PIXI.sound.Sound.from({
    url: path.audioPath + 'progressive-tick.wav',
    volume: 0
  })
  positiveWinSoundSource = PIXI.sound.Sound.from({
    url: path.audioPath + 'win-sound.wav',
    volume: 0
  })

}

export function playFirstDropSound () {
  firstDropSource.play({
    start: 0,
    end: 0.5
  })
}

export function playDropTickSound () {
  dropTickSource.play()
}

export function playPositiveWinSound () {
  positiveWinSoundSource.play()
}


export function VolumeController (v) {
  if (dropTickSource && positiveWinSoundSource && firstDropSource) {
    firstDropSource.volume = v
    dropTickSource.volume = v
    positiveWinSoundSource.volume = v
  }
}
