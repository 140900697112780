import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMyBetsPlinkoService, placeBetPlinkoGameService } from 'network/services/plinkoGame.service'
import { fastModeBox, pixiCancelBall, pixiSetAutoBet, pixiStartMovingBall, setPayoutValue } from 'pixi-js-scripts/plinko-game/bridge'
import { updateCreditWalletWaitQueue } from 'redux-store/redux/slices/auth.slice'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { appendMyBetsPlinko, setBetLock } from 'redux-store/redux/slices/plinkoGame.slice'
import { generateClientSeed } from 'utils/common-functions/index'
import { divide } from 'number-precision'

export const placedBetPlinkoGame = createAsyncThunk('plinko-game/place-bet',
  async ({ payload, id, fastMode, autoBet = false }, thunkApi) => {
    try {
      const newClientSeed = generateClientSeed()
      thunkApi.dispatch(setNewClientSeed(newClientSeed))
      const res = await placeBetPlinkoGameService(payload)
      if (res?.nextServerSeedHash) {
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      if (fastMode) {
        fastModeBox(res.dropDetails)
        thunkApi.dispatch(setBetLock())
        thunkApi.dispatch(updateCreditWalletWaitQueue())
        thunkApi.dispatch(appendMyBetsPlinko())
      } else {
        pixiSetAutoBet(autoBet)
        pixiStartMovingBall(res.dropDetails)
        const winningAmount = `${+(res?.winningAmount)}`
        const multiplier = `${(+(divide(+res?.winningAmount, res?.betAmount)))?.toFixed(2)}x`
        setPayoutValue(winningAmount, multiplier)
      }
      return res
    } catch (error) {
      if (!fastMode) {
        pixiCancelBall()
      }
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsPlinko = createAsyncThunk('plinko-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsPlinkoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
