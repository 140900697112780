import { combineReducers } from '@reduxjs/toolkit'
import gameSetting from './slices/gameSettings.slice'
import crashGame from './slices/crashGame.slice'
import plinkoGame from './slices/plinkoGame.slice'
import authSlice from './slices/auth.slice'
import spaceDiceGameSlice from 'pages/SpaceDiceGame/slice-thunk/spaceDiceGame.slice'

const rootReducer = combineReducers({
  gameSetting,
  crashGame,
  auth: authSlice,
  plinkoGame,
  spaceDiceGame: spaceDiceGameSlice,
})

export default rootReducer
