import React, { useEffect, useState } from 'react'
import Button from 'components/ui-kit/Button/index'
import { CrossIcon } from 'assets/icons/index'
import Popup from 'components/ui-kit/Popup/index'
import MyBets from 'pages/NewCrashGame/components/BetsSidebar/MyBets/index'
import { useDispatch, useSelector } from 'react-redux'
import { getMyBetsPaginated } from 'redux-store/thunk/crashGame.thunk'
import { PAGINATION_LIMIT } from 'utils/constants/index'

const BetHistoryModal = ({
  isOpenHistoryModal,
  setIsOpenHistoryModal,
  currentOffset,
  setCurrentOffset,
  model = true
}) => {
  const dispatch = useDispatch()
  const { myBetsDataPaginated } = useSelector((state) => state.crashGame)
  const { isLoggedIn } = useSelector((state) => state.auth)
  const [betHistoryData, setBetHistoryData] = useState([])

  useEffect(() => {
    if (isLoggedIn && isOpenHistoryModal) {
      setBetHistoryData(prevData => [...prevData, ...myBetsDataPaginated?.rows])
    }
  }, [myBetsDataPaginated])

  const handleLoadMore = () => {
    dispatch(getMyBetsPaginated({
      limit: PAGINATION_LIMIT,
      offset: currentOffset + PAGINATION_LIMIT
    }))
    setCurrentOffset(currentOffset + PAGINATION_LIMIT)
  }

  const JSX = (
    <div className='text-center text-white py-1'>
      <div className='bet-history-title'>
        <h5 className='mb-1 pb-4 border-bottom'>Bet History</h5>
      </div>
      <MyBets betHistoryData={betHistoryData} />
      {betHistoryData?.length > 0 && myBetsDataPaginated?.count > betHistoryData?.length && <Button className='btn-outline-secondary btn-start-playing mb-3 mt-4 py-2' onClick={handleLoadMore}>Load More</Button>}
    </div>
  )

  if (!model) {
    return JSX
  }

  return (
    <Popup className='bet-history-modal' isOpenModal={isOpenHistoryModal}>
      <Button className='btn-outline-primary btn-close-modal p-0' onClick={() => { setIsOpenHistoryModal(false); setBetHistoryData([]) }}>
        <div>
          <CrossIcon />
        </div>
      </Button>
      {JSX}
    </Popup>
  )
}

export default BetHistoryModal
