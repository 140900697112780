import { getTranslation } from 'network/messageTranslationHelpers/index'
import { getAuthToken } from 'utils/common-services/cookie.service'
import { openErrorToaster, openSuccessToaster } from 'utils/common-services/toaster.service'
import {
  getUserInformationEvent,
} from "@enigma-lake/zoot-platform-sdk"

export const requestHandler = (request) => {
  if (request.handlerEnabled) {
    const authToken = getAuthToken()
    if (authToken) {
      request.headers.authorization = `Bearer ${authToken}`
    }
  }
  return request
}

export const responseSuccessHandler = (response) => {
  const { responseType = 'json', successMessage } = response.config || {}

  if (responseType === 'blob') {
    return { file: response.data, headers: response.headers }
  }

  // Open Success Toaster
  return response.data.data
}

export const errorHandler = (error) => {
  if (error.response.status === 401) {

    // When User get's inactive for 20 minutes, this will show a login alert to the user again to play the game again
    getUserInformationEvent()
    return Promise.reject(error.response.data.errors)
  }

  openErrorToaster({ message: error.response.data.errors[0].description })
  return Promise.reject(error.response.data.errors)
}
