import { lazy } from 'react'
import { ROUTE_PATHS } from 'utils/constants/index'
const NewCrashGame = lazy(() => import('pages/NewCrashGame/index'))
const PlinkoGame = lazy(() => import('pages/PlinkoGame/index'))
const SpaceDiceGame = lazy(() => import('pages/SpaceDiceGame/index'))

const routesList = [
  {
    path: ROUTE_PATHS.crashGame,
    component: NewCrashGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.plinkoGame,
    component: PlinkoGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.spaceDiceGame,
    component: SpaceDiceGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  }
]

export default routesList
