import React from 'react'
import './Popup.scss'

const Popup = ({ handleClose, className, content, children, isOpenModal }) => {
  return (
    <div className={`modal fade show ${className} ${isOpenModal ? 'd-block' : ''}`} id='popup_modal'>
      <div className='modal-dialog modal-lg modal-dialog-centered'>
        <div className='modal-content'>
          {/* <!-- MODAL BODY --> */}
          <div className='modal-body'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
