import {
  path
} from "./settings.js"
import {
  gameManager
} from "./gameManager.js"
import {
  loadSounds
} from "./soundManager.js"
import { showBgUI } from "./bgApp/bgUI.js"

export let assetsLoaded = false

export function startLoadingAssets (setIsPixiAssetsLoaded) {

  loadAssets()
    .then(function () {
      onAssetsLoaded(setIsPixiAssetsLoaded)
    })
    .catch(function (e) {
      console.error(e)
    })
}

async function loadAssets () {

  PIXI.Assets.addBundle('Images', {
    plinko: path.spriteSheetsPath + 'plinko.json',
    lightning: path.spriteSheetsPath + 'lightning.json',
    pyramid: path.imagePath + 'pyramid.png',
    diamondred: path.imagePath + 'diamondred.png',
    diamondgreen: path.imagePath + 'diamondgreen.png',
    winningPopup: path.imagePath + 'winningpopup.png',
    plinkoAnim: path.spriteSheetsPath + 'plinkoAnim.json',
    shineEffect: path.spriteSheetsPath + 'shineEffect.json',
    sqBG: path.spriteSheetsPath + 'sqBg.png',
  })

  loadSounds()

  await PIXI.Assets.loadBundle('Images')
}

function onAssetsLoaded (setIsPixiAssetsLoaded) {
  assetsLoaded = true

  showBgUI()

  setIsPixiAssetsLoaded(true)
  gameManager()

}
