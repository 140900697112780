import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  cancelCrashBetService, escapeCrashBetService,
  getMyBetsService, verifyProvableFairCrashService, getTopBetsService, placeBetCrashGameService, getPreviousRoundBetsService
} from 'network/services/crashGame.service'
import { appendCurrentBetsToMyBets, setCurrentPlacedBet, setPlacedCrashBetId, setPlacingOrCashoutLoading } from 'redux-store/redux/slices/crashGame.slice'
import { openSuccessToaster } from 'utils/common-services/toaster.service'
import { APPEND_TYPES } from 'utils/constants/index'

export const placedBetCrashGame = createAsyncThunk('crash-game/place-bet-crash-game', async ({ payload, id }, thunkApi) => {
  try {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: true }))
    const res = await placeBetCrashGameService(payload)
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: res.id, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: res, reset: false }))
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return res
  } catch (error) {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const escapeCrashBet = createAsyncThunk('crash-game/player-escape-crashGame', async ({ betId, id, onSuccess }, thunkApi) => {
  try {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: true }))
    const res = await escapeCrashBetService({ betId })
    onSuccess()
    const store = thunkApi.getState()
    if (store?.crashGame?.myBetsData) {
      const state = store?.crashGame
      const escapedBet = [res]
      const betExist = state?.myBetsData?.rows?.some(bet => bet?.id === escapedBet?.[0]?.id)
    }
    thunkApi.dispatch(appendCurrentBetsToMyBets({ type: APPEND_TYPES.ESCAPE, data: [res] }))
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: null, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: null, reset: false }))
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return res
  } catch (error) {
    thunkApi.dispatch(setPlacingOrCashoutLoading({ index: id, value: false }))
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cancelCrashBet = createAsyncThunk('crash-game/cancel-bet-crash-game', async ({ id, betId }, thunkApi) => {
  try {
    const res = await cancelCrashBetService({ betId })
    thunkApi.dispatch(setPlacedCrashBetId({ id, betId: null, reset: false }))
    thunkApi.dispatch(setCurrentPlacedBet({ id, betDetails: null, reset: false }))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBets = createAsyncThunk('crash-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsPaginated = createAsyncThunk('crash-game/myBets-paginated', async (params, thunkApi) => {
  try {
    const res = await getMyBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getPreviousRoundBets = createAsyncThunk('crash-game/previous-round-placed-bets', async (params, thunkApi) => {
  try {
    const res = await getPreviousRoundBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const verifyProvableFairCrash = createAsyncThunk('crash-game/check-provable-fair', async ({ payload }, thunkApi) => {
  try {
    const res = await verifyProvableFairCrashService(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
export const getTopBets = createAsyncThunk('crash-game/topBets', async (params, thunkApi) => {
  try {
    const res = await getTopBetsService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
