import axiosInstance, { microServices } from 'network/apis/index'
import { METHOD_TYPES } from 'utils/constants/index'

export const placeBetPlinkoGameService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'plinko-game/place-bet', data, {
    server: microServices.USER_URL
  })
}

export const getMyBetsPlinkoService = (params) => {
  return axiosInstance(METHOD_TYPES.get, 'plinko-game/my-bets', {}, {
    params,
    server: microServices.USER_URL
  })
}

