import React from 'react'
import './HowToPlay.scss'
import Popup from 'components/ui-kit/Popup/index'
import Button from 'components/ui-kit/Button/index'
import { CrossIcon } from 'assets/icons/index'

const HowToPlay = ({
  setIsOpenHowToPlay,
  isOpenHowToPlay
}) => {
  return (
    <Popup className='how-to-play-modal' isOpenModal={isOpenHowToPlay}>
      <Button className='btn-outline-primary btn-close-modal p-0' onClick={() => setIsOpenHowToPlay(false)}>
        <div>
          <CrossIcon />
        </div>
      </Button>
      <div className='how-to-play-content-body text-white pt-1 pb-5'>
        <div className='how-to-play-title'>
          <h5>Game Limits</h5>
          <p className='fw-normal mb-4'>
            To the Moon is a new game by <a className='text-white' href='https://dingdingding.com/' rel='noreferrer' target='_blank'>DingDingDing.com!</a> You can win many times over with our innovative play for fun crash game! To the Moon is built on a provably fair system. Have Fun!
          </p>
        </div>
        <div className='game-content-box'>
          {/* items */}
          <div className='game-content-items'>
            <h5 className='mb-3'>Game Details</h5>
            <ul>
              <li>The win multiplier starts at 1x and grows more and more as the Character takes off.</li>
              <li>Your winnings are calculated at the multiplier at which you made a Cash Out, multiplied by your bet.</li>
              <li>Before the start of each round, our provably fair random number generator generates the multiplier at which the Character will fly away. You can check the honesty of this generation by clicking on icon, opposite the result, in the History tab</li>
            </ul>
          </div>
          {/* items */}
          <div className='game-content-items'>
            <h5 className='mb-3'>Bet & Cashout</h5>
            <ul>
              <li>You can make multiple bets simultaneously, by adding a second bet panel. To add a second bet panel, press the plus icon, which is located on the top right corner of the last bet panel or on the center of last bet panel.</li>
              <li>Press the “Cash Out” button to cash out your winnings. Your win is your bet multiplied by the Cash Out multiplier.</li>
              <li>Your bet is lost, if you didn’t cash out before the character flies away.</li>
            </ul>
          </div>
          {/* items */}
          <div className='game-content-items'>
            <h5 className='mb-3'>Auto Play & Auto Cash Out</h5>
            <ul>
              <li>Auto Play is activated from the “Auto” tab on the Bet Panel, by pressing the “Auto Play” button.</li>
              <li>Auto Cash Out is available from the “Auto” tab on the Bet panel. After activation, your bet will be automatically cashed out when it reaches the multiplier entered</li>
            </ul>
          </div>
          {/* items */}
          <div className='game-content-items'>
            <h5 className='mb-3'>Live Bets & Statistics</h5>
            <ul>
              <li>On the right side of the game interface (or under the Bet Panel on mobile), is located the Live Bets panel. Here you can see all bets that are being made in the current round.</li>
              <li>In the “My Bets” panel you can see all of your bets and Cash Out information.</li>
              <li>In the “Top” panel, game statistics are located. You can browse wins by amount, or Cash Out multiplier, and see the biggest round multipliers.</li>
            </ul>
          </div>
          {/* items */}
          {/* <div className='game-content-items'>
            <h5 className='mb-3'>Free Bets</h5>
            <ul>
              <li>You can check the status of Free Bets, from the Game Menu &darr; Free Bets. Free Bets are awarded by the operator, or by the Rain Feature.</li>
            </ul>
          </div> */}
          {/* items */}
          {/* <div className='game-content-items'>
            <h5 className='mb-3'>Randomisation</h5>
            <ul>
              <li>The multiplier for each round is generated by a “Provably Fair” algorithm and is completely transparent, and 100% fair.</li>
              <li>Read more about provably fair system</li>
              <li>You can check the Provably Fair settings from the Game menu & Provably Fair settings.</li>
              <li>You can check the fairness of each round by pressing icon, below the game section or inside “Top” tabs.</li>
            </ul>
          </div> */}
          {/* items */}
          <div className='game-content-items'>
            <h5 className='mb-3'>Other</h5>
            <ul>
              <li>If the internet connection is interrupted when the bet is active, the game will auto cash out with the current multiplier, and the winning amount will be added to your balance.</li>

              <li>In the event of a malfunction of the gaming hardware/software, all affected game bets and payouts are rendered void and all affected bets are refunded.</li>

            </ul>
          </div>
        </div>
      </div>
    </Popup>
  )
}
export default HowToPlay
