import { createSlice } from '@reduxjs/toolkit'
import { getMyBetsPlinko, placedBetPlinkoGame } from 'redux-store/thunk/plinkoGame.thunk'
import { DEFAULT_PAGE_CALLS, DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from 'utils/constants/index'

const initialState = {
  placedBetData: null,
  betLock: false,
  placedBets: [],
  betLoading: false,
  betPlacedInProgress: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  liveBets: {
    count: 0,
    rows: []
  },
  myBetsLoading: false,
  lightningBoardLoading: false,
  lightningBoardBallMultipliers: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  lightningBoardPayouts: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
}

const {
  actions: {
    setBetLock,
    setLightningBoardDetails,
    appendMyBetsPlinko,
    setLiveBets
  },
  reducer
} = createSlice({
  name: 'plinkoGame',
  initialState,
  reducers: {
    setLiveBets: (state, action) => {
      return {
        ...state,
        liveBets: {
          count: action.payload?.count ?? 0,
          rows: action.payload?.rows ?? []
        }
      }
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setLightningBoardDetails: (state, action) => {
      return {
        ...state,
        lightningBoardBallMultipliers: action.payload?.lightningBoardBallMultipliers ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
        lightningBoardPayouts: action.payload?.lightningBoardPayouts ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
      }
    },
    appendMyBetsPlinko: (state, action) => {
      let _placedBets = [...(state?.placedBets ?? [])]
      const _betToAppend = _placedBets?.[_placedBets.length - 1]
      _placedBets = _placedBets?.slice(0, _placedBets.length - 1)
      const { nextServerSeedHash, ...data } = _betToAppend || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        return {
          ...state,
          placedBets: _placedBets,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true,
          betPlacedInProgress: true
        }
      })
      .addCase(placedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBets: [action.payload, ...(state?.placedBets ?? [])],
          placedBetData: action.payload,
          betLoading: false,
          betPlacedInProgress: false
        }
      })
      .addCase(placedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false,
          betPlacedInProgress: false,
        }
      })
      .addCase(getMyBetsPlinko.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true

        }
      })
      .addCase(getMyBetsPlinko.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false

        }
      })
  }
})

export {
  setBetLock,
  setLightningBoardDetails,
  appendMyBetsPlinko,
  setLiveBets
}

export default reducer
