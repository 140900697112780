export const modes = 
    {
    easy: {
        moonTint: 0xFFFFFF,
        sky: '',
        diamond: 'diamondgreen',
        Background:'BG'

    },
    normal: {
        moonTint: 0xFFBB0B,
        sky: '',
        diamond: 'diamondgreen',
        Background:'BG'
    },

    hard: {
        moonTint: 0xFA0BFF,
        sky: '',
        diamond: 'diamondgreen',
        Background:'BG'
    },

    inferno: {
        moonTint: '',
        sky: 'inferno',
        diamond: 'diamondred',
        Background:'BGInferno'
    }
    }
