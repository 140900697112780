import { createSlice } from '@reduxjs/toolkit'
import { gameClose, userLogin } from 'redux-store/thunk/auth.thunk'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import { ZOOT_CURRENCY_TYPES } from 'utils/constants/index'

const defaultAuthState = {
  creditWalletWaitQueue: [],
  userInfo: null,
  currencyId: null,
  currencyCode: null,
  zootCurrencyCode: null,
  isLoggedIn: false,
  showName: false,
  zootUserInfo: null
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    appendCreditWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditWalletWaitQueue: [action.payload, ...state.creditWalletWaitQueue]
      }
    },
    resolveAllCreditWalletWaitQueue: (state, action) => {
      let _creditWalletWaitQueue = [...state.creditWalletWaitQueue]
      const _userInfoWallet = _creditWalletWaitQueue?.[0] ?? state.userInfo.wallet
      _creditWalletWaitQueue = []
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    updateCreditWalletWaitQueue: (state, action) => {
      let _creditWalletWaitQueue = [...state.creditWalletWaitQueue]
      const _userInfoWallet = _creditWalletWaitQueue[_creditWalletWaitQueue.length - 1] ?? state.userInfo.wallet
      _creditWalletWaitQueue = _creditWalletWaitQueue?.slice(0, _creditWalletWaitQueue.length - 1)
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    setShowName: (state, action) => {
      return {
        ...state,
        showName: !state.showName
      }
    },
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: action.payload
      }
    }),
    setZootUserInfo: (state, action) => {
      const { payload } = action;
      if(!payload?.accessToken || payload?.accessToken === 'no-access-token'){
        return ({
          ...state,
          zootUserInfo: null,
          isLoggedIn: false,
        })
      }

      return (
        {
          ...state,
          zootUserInfo: action.payload,
        }
      )
    },
    setZootCurrencyCode: (state, action) => {
      const currency = action.payload.currency
      let zootCurrencyCode

      if (currency === ZOOT_CURRENCY_TYPES.GOLD.name) {
        zootCurrencyCode = ZOOT_CURRENCY_TYPES.GOLD.code
      } else if (currency === ZOOT_CURRENCY_TYPES.SWEEPS.name) {
        zootCurrencyCode = ZOOT_CURRENCY_TYPES.SWEEPS.code
      }

      return ({
        ...state,
        zootCurrencyCode
      })
    },
    updateWallet: (state, { payload }) => ({
      ...state,
      userInfo: {
        ...state?.userInfo,
        wallet: {
          ...state?.userInfo?.wallet,
          [ZOOT_CURRENCY_TYPES.GOLD.code]: payload?.[ZOOT_CURRENCY_TYPES.GOLD.balanceName],
          [ZOOT_CURRENCY_TYPES.SWEEPS.code]: payload?.[ZOOT_CURRENCY_TYPES.SWEEPS.balanceName]
        }
      }
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        const { user, accessToken } = action.payload
        return {
          ...state,
          userInfo: { user, wallet: { ...state?.userInfo?.wallet } },
          isLoggedIn: true
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          userInfo: { ...state?.userInfo, user: { ...state?.userInfo?.user, ...action.payload } }
        }
      })
      .addCase(gameClose.fulfilled, (state, action) => {
        return defaultAuthState
      })
  }
})

export const {
  setShowName,
  setUserInfo,
  updateWallet,
  setZootUserInfo,
  setZootCurrencyCode,
  updateCreditWalletWaitQueue,
  appendCreditWalletWaitQueue,
  resolveAllCreditWalletWaitQueue,
} = authSlice.actions

export default authSlice.reducer
