import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import './Header.scss'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import useGameSettingsUpdateSocketNameSpace from 'socket-resources/hooks/useGameSettingsUpdateSocketNameSpace'
import { getAllCurrency, getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
import { updateGameSetting } from 'redux-store/redux/slices/gameSettings.slice'
import BetHistoryModal from 'pages/NewCrashGame/components/BetHistoryModal/index'
import HowToPlay from 'pages/NewCrashGame/components/HowToPlay/index'
import ProvablyFair from 'pages/NewCrashGame/components/ProvablyFair/index'
import GameLimit from 'pages/NewCrashGame/components/GameLimit/index'
import { userLogin } from 'redux-store/thunk/auth.thunk'
import { DEFAULT_GAME_ID, ROUTE_PATHS } from 'utils/constants/index'
import { setZootCurrencyCode, setZootUserInfo, updateWallet } from 'redux-store/redux/slices/auth.slice'
import { setExpendedGameView } from 'redux-store/redux/slices/gameSettings.slice'
import {
  EVENTS,
  getUserCurrencyEvent,
  getUserInformationEvent,
  // getGameViewEvent,
} from "@enigma-lake/zoot-platform-sdk"
import '@enigma-lake/zoot-platform-sdk/dist/bundle.css'
// import ExpandGame from 'components/ui-kit/ExpandGame/ExpandGame'

const NewHeader = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoggedIn, zootUserInfo } = useSelector((state) => state.auth)
  const { gameSound, } = useSelector((state) => state.gameSetting)
  const listenGameSettingsUpdate = useGameSettingsUpdateSocketNameSpace()
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  const [isOpenProvablyFair, setIsOpenProvablyFair] = useState(false)
  const [isOpenGameLimits, setIsOpenGameLimits] = useState(false)
  const [isOpenHowToPlay, setIsOpenHowToPlay] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [gameId, setGameId] = useState('')

  const musicRef = useRef(null)
  const buttonClickSoundRef = useRef(null)

  const handleLoginUser = () => {
    const { nickname, accessToken, id } = zootUserInfo

    const params = {
      payload: {
        id,
        nickname,
        accessToken,
      },
      history
    }
    dispatch(userLogin(params))
  }

  useEffect(() => {
    const gamePath = window.location.pathname
    let id = ''
    switch (gamePath) {
      case ROUTE_PATHS.crashGame:
        id = DEFAULT_GAME_ID.CRASH
        break
      case ROUTE_PATHS.plinkoGame:
        id = DEFAULT_GAME_ID.PLINKO
        break
      case ROUTE_PATHS.spaceDiceGame:
        id = DEFAULT_GAME_ID.SPACE_DICE_GAME
        break
      default:
        id = ''
    }
    setGameId(id)
  }, [])

  useEffect(() => {
    if (zootUserInfo && zootUserInfo.accessToken) {
      handleLoginUser()
    }
  }, [zootUserInfo])

  useEffect(() => {
    musicRef.current = new Audio()
    musicRef.current.src = '/assets/music/game-music.mp3'
    musicRef.current.loop = true

    buttonClickSoundRef.current = new Audio()
    buttonClickSoundRef.current.src = '/assets/music/button-click.wav'

    dispatch(getAllCurrency())
    dispatch(getGameSetting())
    window.addEventListener('beforeunload', () => {
    })

    const clearGameSettingsUpdate = listenGameSettingsUpdate(e => {
      handleGameSettingsUpdate(e.data)
    })

    return () => {
      musicRef.current?.pause()
      buttonClickSoundRef.current?.pause()
      clearGameSettingsUpdate()
    }
  }, [])

  useEffect(() => {
    if (gameId === DEFAULT_GAME_ID.CRASH && !musicRef.current.isPlaying) {
      musicRef.current = new Audio()
      musicRef.current.src = '/assets/music/game-music.mp3'
      musicRef.current.loop = true
      musicRef.current.volume = 0.90
    }
    if (gameId === DEFAULT_GAME_ID.PLINKO && !musicRef.current.isPlaying) {
      musicRef.current = new Audio()
      musicRef.current.src = '/assets/music/beduin.wav'
      musicRef.current.loop = true
      musicRef.current.volume = 0.90
    }
  }, [gameId])

  const playClickSound = () => {
    if (buttonClickSoundRef.current && gameSound) {
      buttonClickSoundRef.current?.play()
    }
  }

  const systemGameDataSchemas = yup.array().of(yup.object().shape({
    id: yup.string().required(),
    gameId: yup.number().required(),
    maxBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    minBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    maxProfit: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    houseEdge: yup.number().required(),
    minOdds: yup.number().required(),
    maxOdds: yup.number().required(),
    minAutoRate: yup.number().required(),
    createdAt: yup.string().required(),
    updatedAt: yup.string().required()
  })).min(1)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchUserInformation())
    }
  }, [isLoggedIn])

  useEffect(() => {
    const fetchUserCurrency = async () => {
      await getUserCurrencyEvent()
    }
    fetchUserCurrency()
  }, [zootUserInfo, isLoggedIn])

  useEffect(() => {
    const fetchUserInfo = async () => {
      await getUserInformationEvent()
    }

    const handleMessage = (event) => {
      if (event.data && event.data.event_id) {
        switch (event.data.event_id) {
          case EVENTS.EL_GET_USER_CURRENCY:
            dispatch(setZootCurrencyCode(event.data.data))
            break
          case EVENTS.EL_USER_BALANCE:
            dispatch(updateWallet(event.data.data))
            break
          case EVENTS.EL_USER_INFORMATION:
            dispatch(setZootUserInfo(event.data.data))
            break
          case EVENTS.EL_GET_EXPANDED_GAME_VIEW:
            // dispatch(setExpendedGameView(event.data.data))
            break
          case EVENTS.EL_LOGIN_USER:
            break
          default:
        }
      }
    }

    window.addEventListener("message", handleMessage)

    fetchUserInfo()
    // getGameViewEvent()

    // Clean up the event listener
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  const handleGameSettingsUpdate = async (data) => {
    if (data) {
      const isValid = await systemGameDataSchemas.isValid(data)
      if (isValid) {
        dispatch(updateGameSetting(data))
      }
    }
  }

  return (
    <>
      <HowToPlay
        setIsOpenHowToPlay={setIsOpenHowToPlay}
        isOpenHowToPlay={isOpenHowToPlay}
      />
      <BetHistoryModal
        setIsOpenHistoryModal={setIsOpenHistoryModal}
        isOpenHistoryModal={isOpenHistoryModal}
        currentOffset={currentOffset}
        setCurrentOffset={setCurrentOffset}
      />
      <ProvablyFair
        setIsOpenProvablyFair={setIsOpenProvablyFair}
        isOpenProvablyFair={isOpenProvablyFair}
        playClickSound={playClickSound}
      />
      <GameLimit
        setIsOpenGameLimits={setIsOpenGameLimits}
        isOpenGameLimits={isOpenGameLimits}
      />
    </>
  )
}

export default NewHeader
