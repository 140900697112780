import { app } from "./appPixi.js"
import { balls, pegs, boxes, uiCreated, bgDestroyed, gameAssets, containers, setupPyramid, winningPopup } from "./gameUI.js"
import { setDevice } from "./manageResponsive.js"
import { adjustImageToAS } from "./utility.js"
import { appTransform } from "./appPixi.js"

const bgAssets = (delta) => {
  // Object.keys(gameAssets.background.left).forEach(asset => gameAssets.background.left[asset].spriteState(delta))
  // Object.keys(gameAssets.background.right).forEach(asset => gameAssets.background.right[asset].spriteState(delta))
}

export const tickerFunction = () => {
  app.ticker.add((dt) => {

    if (!uiCreated) return

    boxes.forEach((box) => {
      box.sprite.BoxBounceEffect(dt)
    })

    balls.forEach((ball) => {
      ball.drop(dt)
    })
    pegs.forEach((peg) => {
      peg.shineState(dt)
    })

    if (winningPopup && winningPopup.visible) {
      winningPopup.currentState(dt)
    }

    if (!bgDestroyed) bgAssets(dt)
  })
}
export function distance (currentPos, targetPos) {
  let distance = Math.pow((targetPos.x - currentPos.x), 2) + Math.pow((targetPos.y - currentPos.y), 2)
  distance = Math.sqrt(distance)
  return distance
}

export function moveTowards (currentPos, targetPos, speed) {
  let xDirection = targetPos.x - currentPos.x
  let yDirection = targetPos.y - currentPos.y
  currentPos.x += (xDirection * speed)
  currentPos.y += (yDirection * speed)
  return currentPos
}
