import { setDevice } from "./manageResponsive.js"
import { config } from "./settings.js"

export const app = new PIXI.Application({
  transparent: true,
  backgroundAlpha: 0,
  antialias: true,
  resolution: 2.2,
  resizeTo: document.getElementById('pixi-plinko-game')
})


function resizefunction () {
}

if (window.innerWidth <= 767) {
  setDevice('Mobile')
} else {
  setDevice('PC')
}

globalThis.__PIXI_APP__ = app

export function appTransform (x, y) {
  app.renderer.resize(x, y)

}
