export const S3_BUCKET_ENDPOINT = 'https://gs-custom-games-storage.s3.amazonaws.com'

export const BET_RESULT = Object.freeze({
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
})

export const DEFAULT_GAME_ID = Object.freeze({
  PLINKO: '1',
  CRASH: '2',
  SPACE_DICE: '3',
})

export const ZOOT_CURRENCY_TYPES = Object.freeze({
  GOLD: { name: 'gold', balanceName: 'goldBalance', code: 'GB' },
  SWEEPS: { name: 'sweeps', balanceName: 'sweepsBalance', code: 'SB' }
})
