import { createAsyncThunk } from '@reduxjs/toolkit'
import { gameCloseService, loginService } from 'network/services/auth.service'
import { setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { signIn, signOut } from 'utils/common-services/cookie.service'

export const userLogin = createAsyncThunk('user/login', async ({ payload, history }, thunkApi) => {
  try {
    const res = await loginService(payload)
    if (res) {
      thunkApi.dispatch(setServerSeedHash(res?.serverSeedHash))
      signIn({ token: res?.userAccessToken })
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const gameClose = createAsyncThunk('user/game-close', async (data, thunkApi) => {
  try {
    const res = await gameCloseService()
    if (res) {
      signOut()
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
