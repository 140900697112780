import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import { appRoot } from './assets/dom/domNodes'
import 'assets/translation/index'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  appRoot
)
