// // Comment these three lines in standalone mode
import { appendMyBetsPlinko, setBetLock } from "redux-store/redux/slices/plinkoGame.slice.js"
import store from "redux-store/store.js"
import { countOnes } from "utils/common-functions/index.js"
import { DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from "utils/constants/index.js"

import { startLoadingAssets } from "./assetsLoad.js"
import { boxBounceEffect, changeNumberOfLines, clearAllBalls, createBall, createUI, lightningModeUI, normalModeUI, removeLightningMode, setupPayoutText, startMovingBall, setupModes, setWinAmount } from "./gameUI.js"
import { playDropTickSound, playFirstDropSound, playPositiveWinSound } from "./soundManager.js"
import { updateCreditWalletWaitQueue } from "redux-store/redux/slices/auth.slice.js"
import { isPixiStandAlone, config } from "./settings.js"

let autoBet = false
let noOfBalls = 0

export function pixiGetAutoBet () {
  return autoBet
}

export function pixiSetAutoBet (_autoBet = false) {
  autoBet = _autoBet
}

export function pixiGetNoOfBalls () {
  return noOfBalls
}

export function pixiIncrementNoOfBalls () {
  noOfBalls += 1
}

export function pixiSetNoOfBalls (_noOfBalls) {
  noOfBalls = _noOfBalls
}

export const pixiCreateBall = () => createBall()

export const pixiStartMovingBall = (ballPath) => {
  pixiIncrementNoOfBalls()
  const ballPathArray = ballPath.split('').map(ele => +ele)
  startMovingBall(ballPathArray)
  pixiCreateBall()
}

export const pixiCancelBall = () => clearAllBalls()

export const pixiChangeNumberOfLines = (lines) => changeNumberOfLines(lines)

export const pixiSetPayouts = (payouts) => setupPayoutText(payouts)

export const boxNumbers = {
  Red: 1,
  Orange: 2,
  Pink: 3,
  Purple: 5,
  Blue: 7,
  Green: 8
}

export const boxColor = {
  red: 0xC82020,
  orange: 0xFC6F1F,
  pink: 0xB122F4,
  purple: 0x8951FF,
  blue: 0x346DFF,
  green: 0x3CBCB4,
  yellow: 0xFFBD00,
  white: 0xFFFFFF,
  alpha: 1
}

export const fastModeBox = (dropDetails) => {
  // Comment these lines in standalone mode
  const boxNumber = countOnes(dropDetails)
  boxBounceEffect(boxNumber)
}


export const dispatching = (isLastBall) => {
  // Comment this line in standalone mode
  if (!isPixiStandAlone) {
    dispatchRoundEnd()
  }
  if (isLastBall) {
    pixiSetNoOfBalls(0)
    store.dispatch(setBetLock())
  }
}

export const dispatchRoundEnd = () => {
  store.dispatch(updateCreditWalletWaitQueue())
  store.dispatch(appendMyBetsPlinko())
}

export const setBallSpeed = (speed) => {  //const value=0.75
  config.ball.animationSpeed = speed
}

export const pixiSlowBall = () => {
  setBallSpeed(0.75)
}

export const pixiFastBall = () => {
  setBallSpeed(2)
}

const data = [
  {
    position: [1, 1],
    multiplier: "3x"
  },
  {
    position: [4, 5],
    multiplier: "7x"
  },
  {
    position: [6, 7],
    multiplier: "12x"
  }
]

export const pixiLightningMode = (
  ballMultipliers = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  payouts = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
) => {
  lightningModeUI(ballMultipliers)
  pixiSetPayouts(payouts)
}

export const pixiNormalMode = (payouts) => {
  removeLightningMode()
  pixiSetPayouts(payouts)
}
export const setGameMode = (index) => {
  setupModes(index)
}

export const setPayoutValue = (str1, str2) => {  // str1=90,000,000GC  and str2=90c
  setWinAmount(str1, str2)
}

// Sounds
export const playDropTick = () => {
  playDropTickSound()
}

export const playPositiveWin = () => {
  playPositiveWinSound()
}

export const playFirstDrop = () => {
  playFirstDropSound()
}
