import { DEFAULT_PRECISION_MONEY } from 'utils/constants/index'

export const formatDate = (originalDateString) => {
  const date = new Date(originalDateString)

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  const formattedDate = `${day} ${months[monthIndex]} ${year}`
  return formattedDate
}

export const formatTime = (originalDateString) => {
  const date = new Date(originalDateString)

  const hours = date.getHours()
  const minutes = date.getMinutes()

  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM

  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`

  return formattedTime
}

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

/**
 *
 * @param {Number} n
 * @returns {Number} factorial of n
 */
export const factorial = n => {
  let fact = 1
  for (let i = 2; i <= n; i++) {
    fact *= i
  }
  return fact
}

/**
 *
 * @param {Number} n
 * @param {Number} r
 * @returns {Number} combination of n over r
 */
export const combination = (n, r) => {
  if (r > n) {
    return 0
  }
  return factorial(n) / (factorial(r) * factorial(n - r))
}

/**
 *
 * @param {Number} numberOfCoins
 * @param {Number} minimumChosenOutcome
 * @returns {Number} probability of minimum X number of favorable outcomes
 */
export const getCoinOutcomeProbability = (numberOfCoins, minimumChosenOutcome) => {
  const n = numberOfCoins - minimumChosenOutcome
  let sum = 0
  for (let i = 0; i <= n; i++) {
    sum += combination(numberOfCoins, i)
  }
  return sum / 2 ** numberOfCoins
}

/**
 * To calculate flip coin potential win
 * @param {number} betAmount
 * @param {number} numberOfCoins
 * @param {number} minimumChosenOutcome
 * @param {number} houseEdge
 * @returns {number}
 */
export const getFlipCoinPotentialWin = (betAmount, numberOfCoins, minimumChosenOutcome, houseEdge) => {
  const probability = getCoinOutcomeProbability(numberOfCoins, minimumChosenOutcome)
  const odds = (1 / probability) * (1 - houseEdge / 100)
  return +Number((betAmount * odds)).toFixed(2)
}

/**
 *
 * @param {string} s
 * @returns {Number} count of one
 */
export const countOnes = (s) => {
  let count = 0
  for (const i of s) {
    if (i === '1') {
      count++
    }
  }
  return count
}

/**
 *
 * @param  {...Array} a Specify arrays to do cartesian product
 * @returns {Array.<Array.<number[]>>} Returns the cartesian product of given arrays
 */
export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed += availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

/**
 *
 * @param {number} value
 * @param {number} precision
 * @returns {number | string} Returns precision value for specified decimal places
 */
export const getPrecision = (value, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(value * precisionDivide) / precisionDivide
  return result || 0
}

/**
 *
 * @param {number} x
 * @returns {string} valid million based comma value in string
 */
export const numberWithCommas = (x) => {
  if (x === null || x === undefined || Number(x) === Number.isNaN) {
    return ''
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
