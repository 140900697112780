import { toast } from 'react-toastify';
import {
  showNotificationEvent
} from "@enigma-lake/zoot-platform-sdk"

export const openErrorToaster = ({ message, autoClose = 5000 }) => {
  showNotificationEvent({
    type: 'error',
    message,
  })
  return; // remove this to later use toast in local
  toast.error(message, {
    position: 'top-right',
    autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}

export const openSuccessToaster = ({ message }) => {
  showNotificationEvent({
    type: 'success',
    message,
  })
  return; // remove this to later use toast in local
  toast.success(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}

export const openInfoToaster = ({ message }) => {
  toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}

export const openWarningToaster = ({ message }) => {
  toast.warn(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}
