import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMyBetsService, getTopBetsService, placeBetSpaceDiceGameService } from 'pages/SpaceDiceGame/spaceDiceGame.service'
import { setPlacedBetsData } from './spaceDiceGame.slice'

export const placedBetSpaceDiceGame = createAsyncThunk('space-dice-game/place-bet-dice-game', async ({ payload }, thunkApi) => {
  try {
    const { onSuccess } = payload
    const res = await placeBetSpaceDiceGameService(payload)
    onSuccess()
    thunkApi.dispatch(setPlacedBetsData(res))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsSpaceDiceGame = createAsyncThunk('space-dice-game/myBets', async (data, thunkApi) => {
  try {
    const res = await getMyBetsService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
