import { createSlice } from '@reduxjs/toolkit'
import { diceRed, moveDiceTarget } from 'pixi-js-scripts/space-dice-game/slider'
import { getMyBetsSpaceDiceGame, placedBetSpaceDiceGame } from 'pages/SpaceDiceGame/slice-thunk/spaceDiceGame.thunk'
import { BET_RESULT } from '../constants'

const initialState = {
  betHistoryData: [],
  resultData: [],
  betPlacedInProgress: false,
  placedBetDiceGameData: [],
  topBetsData: [],
  myBetsData: [],
  myBetsLoading: false,
  topBetsLoading: false,
  sliderData: {
    left: 2500,
    center: 5000,
    right: 7499
  },
  roundBetHistoryData: null
}

const {
  actions: {
    setPlacedBetsData,
    setSliderData,
    resetResult,
    setRoundBetHistoryData
  },
  reducer
} = createSlice({
  name: 'spaceDiceGame',
  initialState,
  reducers: {
    setRoundBetHistoryData: (state, action) => ({
      ...state,
      roundBetHistoryData: action.payload
    }),
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: [action.payload, ...state.betHistoryData]
      }
    },
    setSliderData: (state, action) => {
      return {
        ...state,
        sliderData: {
          ...state.sliderData,
          ...action.payload
        }
      }
    },
    resetResult: (state, action) => {
      return {
        ...state,
        placedBetDiceGameData: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyBetsSpaceDiceGame.pending, (state, action) => {
        return {
          ...state,
          myBetsLoading: true
        }
      })
      .addCase(getMyBetsSpaceDiceGame.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: false
        }
      })
      .addCase(placedBetSpaceDiceGame.pending, (state, action) => {
        return {
          ...state,
          betPlacedInProgress: true
        }
      })
      .addCase(placedBetSpaceDiceGame.rejected, (state, action) => {
        return {
          ...state,
          betPlacedInProgress: false
        }
      })
      .addCase(placedBetSpaceDiceGame.fulfilled, (state, action) => {
        moveDiceTarget(action.payload?.winningNumber || 5000)
        diceRed(action.payload?.result === BET_RESULT.LOST)
        return {
          ...state,
          betPlacedInProgress: false,
          placedBetDiceGameData: action.payload
        }
      })
  }
})

export default reducer
export {
  setPlacedBetsData,
  setSliderData,
  setRoundBetHistoryData,
  resetResult
}
