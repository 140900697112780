import {
  config
} from "../settings"
import {
  modes
} from "../manageModes"
import {
  spriteSettings,
  adjustImageToAS
} from "../utility"
import {
  isDevice,
  setDevice
} from "../manageResponsive"

export const bgApp = new PIXI.Application({
  resizeTo: window,
  backgroundColor: 0xFFFFFF,
  backgroundAlpha: 0,
  antialias: true,
})

let appWidth = bgApp.view.width
let appHeight = bgApp.view.height

let appAspectRatio = bgApp.view.width / bgApp.view.height

let appAspectRatioX = appWidth / 1920
let appAspectRatioY = appHeight / 1080



let selectedmodes = null //BG
let BGcreated = false

const containers = {

  bgRootContainer: new PIXI.Container(),
  backgroundContainer: new PIXI.Container(),
  moonContainer: new PIXI.Container(),
  starsContainer: new PIXI.Container(),
  skyContainer: new PIXI.Container(),
  dunesContainer: new PIXI.Container(),
}

const bgSprite = new PIXI.Sprite()

const gameAssets = {

  mainBG: new PIXI.Sprite(),
  sky: new PIXI.Sprite(),
  dunes: new PIXI.Sprite(),
  moon: new PIXI.Sprite(),
  moonLight: new PIXI.Sprite(),
}


export function setupModes (index) {
  switch (index) {
    case 0:
      selectedmodes = modes.easy
      break
    case 1:
      selectedmodes = modes.normal
      break
    case 2:
      selectedmodes = modes.hard
      break
    case 3:
      selectedmodes = modes.inferno
      break
  }
  if (BGcreated)
    setupBackground()
}

function createUI () {
  if (!selectedmodes) {
    setupModes(3)
  }
  setupContainers()
  setupGameAssets()
  BGcreated = true

}

export const showBgUI = () => {
  createUI()
}

function setupContainers () {

  //bg
  bgApp.stage.addChild(containers.bgRootContainer)

  containers.bgRootContainer.addChild(containers.backgroundContainer)
  containers.bgRootContainer.addChild(containers.skyContainer)
  containers.bgRootContainer.addChild(containers.starsContainer)
  containers.bgRootContainer.addChild(containers.moonContainer)
  containers.bgRootContainer.addChild(containers.dunesContainer)

  //bg

}

function setupGameAssets () {

  //BG
  if (!BGcreated) {
    createBackground()
    createMoon()
    if (isDevice() == 'PC')
      createStars()
  }
  setupBackground()
  //BG

}


function createBackground () { //bg
  gameAssets.mainBG = spriteSettings(gameAssets.mainBG, PIXI.Assets.get(selectedmodes.Background), containers.backgroundContainer)

  gameAssets.sky = spriteSettings(gameAssets.sky, PIXI.Assets.get(selectedmodes.sky), containers.skyContainer)

  gameAssets.dunes = spriteSettings(gameAssets.dunes, PIXI.Assets.get('dunes'), containers.dunesContainer)
}

function createMoon () { //bg
  gameAssets.moonLight = spriteSettings(gameAssets.moonLight, PIXI.Assets.get('moonlight'), containers.moonContainer)
  gameAssets.moonLight.alpha = 0.5
  gameAssets.moon = spriteSettings(gameAssets.moon, PIXI.Assets.get('moon'), containers.moonContainer)
}

function createStars () { //bg

  for (let index = 0; index < 2; index++) {
    let stars = new PIXI.Sprite()
    stars = spriteSettings(stars, PIXI.Assets.get('stars'), containers.starsContainer)
    stars.position.set(-stars.width * 0.4 + index * stars.width, -stars.height * 0.5)
  }
  containers.starsContainer.scale.set(2 * appAspectRatioX)
}

function setupBackground () { //bg
  containers.bgRootContainer.position.set(appWidth / 2, appHeight / 2)
  gameAssets.mainBG.width = appWidth
  gameAssets.mainBG.height = appHeight

  gameAssets.mainBG.texture = PIXI.Assets.get(selectedmodes.Background)

  gameAssets.sky.texture = PIXI.Assets.get(selectedmodes.sky)
  gameAssets.sky.width = appWidth
  gameAssets.sky.height = appHeight


  if (selectedmodes.moonTint) {
    gameAssets.moonLight.visible = true
    gameAssets.moon.visible = true
    gameAssets.moonLight.tint = selectedmodes.moonTint
    gameAssets.moon.tint = selectedmodes.moonTint
  } else {
    gameAssets.moonLight.visible = false
    gameAssets.moon.visible = false
  }

  if (isDevice() == 'PC') {
    containers.moonContainer.scale.set(1.5 * appAspectRatioX)
    containers.moonContainer.position.set(-appWidth * 0.5 + containers.moonContainer.width * 0.3, -appHeight * 0.5 + containers.moonContainer.height * 0.1)
  } else {
    containers.moonContainer.scale.set(1.1 * appAspectRatioY)
    containers.moonContainer.position.set(0, -appHeight * 0.5 + containers.moonContainer.height * 0.1)
  }

  for (let index = 0; index < containers.starsContainer.children.length; index++) {
    let stars = containers.starsContainer.children[index]
    stars.position.set(-stars.width * 0.4 + index * stars.width, 0)
  }
  containers.starsContainer.scale.set(2 * appAspectRatioX)
  containers.starsContainer.position.set(0, -appHeight * 0.5 + containers.starsContainer.height * 0.1)

  gameAssets.dunes.scale.set(appAspectRatioX * 2)
  gameAssets.dunes.position.set(0, appHeight - gameAssets.dunes.height * 0.5)

}
