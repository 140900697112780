import {
  DEFAULT_PLINKO_PINS
} from "../../utils/constants/index.js"
import {
  app
} from "./appPixi.js"
import {
  config,
  isPixiStandAlone,
  path as basePath,
  gameDisplaySettings,
} from "./settings.js"
import {
  AnimationSprite,
  ObjectPool,
  countCharacters,
  getDirection,
  splitGradientTexture,
  spriteSettings,
  updateBallRow,
} from "./utility.js"
import {
  boxColor,
  boxNumbers,
  dispatching,
  playDropTick,
  playFirstDrop
} from "./bridge.js"
import {
  isDevice,
  setDevice
} from "./manageResponsive.js"
import {
  modes
} from "./manageModes.js"

let ballPool
let pegs = []
let pegsForShine = []
let balls = []
let boxes = []
let path = []
let totalBalls = 0
let time = 1 * 60
const appWidth = app.screen.width
const appHeight = app.screen.height

const appAspectRatio = appWidth / appHeight

const appAspectRatioX = appWidth / 1920
const appAspectRatioY = appHeight / 1080
let uiCreated = false
let boolBallCreated = false
let isSetupBoxes = false
let totalLines = isPixiStandAlone ? 16 : DEFAULT_PLINKO_PINS
// let totalLines = 8;
export let bgDestroyed = false
let bgCreated = false

let pegHolder
let selectedmodes = null
let winningPopup = null


const gameAssets = {
  background: {
    left: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
    right: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
  },
  //Fonts
  fonts: {
    regular: {},
  },
  pyramid: new PIXI.Sprite(),
  diamond: new PIXI.Sprite(),

  winPopup: new PIXI.Sprite(),

  styleGradient: new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 36,
    fontWeight: 'bold',
    fill: ['#ffffff', '#fdde74', '#fbcc27'], // gradient
    stroke: '#a314a0',
    strokeThickness: 5,
    wordWrap: true,
    wordWrapWidth: 440,
    lineJoin: 'round',
  }),

  styleNormal: new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 36,
    fontWeight: 'bold',
    fill: ['#ffffff']
  })
}

let pegsInRows = Array.from({
  length: totalLines
},
  (_, rowIndex) => rowIndex + config.pinInFirstLine
)
const containers = {
  rootContainer: new PIXI.Container(),
  bgContainer: null,
  ballContainer: [],
  lightningContainer: new PIXI.Container(),
  pyramidContainer: new PIXI.Container(),
}

function createUI () {
  if (window.innerHeight > window.innerWidth) {
    setDevice('mobile')
  } else {
    setDevice('PC')
  }
  prepareContainers()
  createPyramid()
  createPegsHolder()
  createPegs(config.pegs)
  setupBoxes()
  createBallsPool()
  uiCreated = true
  if (!selectedmodes)
    setupModes(0)
  setupPyramid()
  createPopup()
}

function createPegsHolder () {
  pegHolder = new PIXI.Sprite(PIXI.Assets.get('sqBG'))
  if (isDevice() == "PC") {
    pegHolder.x = gameDisplaySettings.pc.x
    pegHolder.y = gameDisplaySettings.pc.y
    pegHolder.scale.set((appAspectRatioX / (3 - (0.1 * (totalLines - 9)))))
  } else {
    pegHolder.x = gameDisplaySettings.mobile.x
    pegHolder.y = gameDisplaySettings.mobile.y
    pegHolder.scale.set(appAspectRatioY / 2.3)
  }
  pegHolder.anchor.set(0.5)
  // pegHolder.tint = 0x0f0f11;
  containers.rootContainer.addChild(pegHolder)
  pegHolder.position.set(containers.pyramidContainer.x, 50)
}

function prepareContainers () {
  containers.pyramidContainer = new PIXI.Container()
  containers.rootContainer.addChild(
    containers.pyramidContainer,
  )
  app.stage.addChild(containers.rootContainer)

  containers.rootContainer.name = "RootContainer"
  containers.ballContainer.name = "BallContainer"
  containers.pyramidContainer.name = "pyramidContainer"
  containers.rootContainer.position.set(appWidth / 2, appHeight / 2)
  containers.rootContainer.sortableChildren = true
}

export function setupModes (index) {
  switch (index) {
    case 0:
      selectedmodes = modes.easy
      break
    case 1:
      selectedmodes = modes.normal
      break
    case 2:
      selectedmodes = modes.hard
      break
    case 3:
      selectedmodes = modes.inferno
      break
  }
  // if (uiCreated)
  //   setupPyramid()
}

function createPyramid () { //bg
  gameAssets.pyramid = spriteSettings(gameAssets.pyramid, PIXI.Assets.get('pyramid'), containers.pyramidContainer)
  gameAssets.pyramid.blendMode = PIXI.BLEND_MODES.ADD
  if (isDevice() == 'PC') {
    containers.pyramidContainer.scale.set(1.67 * appAspectRatioX) //1.67
    if (appHeight < containers.pyramidContainer.height) {
      containers.pyramidContainer.scale.set(1.67 * appAspectRatioY) //1.67
    }
    containers.pyramidContainer.position.set(-(appWidth - containers.pyramidContainer.width) / 2.5, 0)
  } else {
    containers.pyramidContainer.scale.set(1.5 * appAspectRatioY) //1.5
    containers.pyramidContainer.position.set(0, -appHeight * 0.5 + containers.pyramidContainer.height * 0.5)
  }

  gameAssets.diamond = spriteSettings(
    gameAssets.diamond,
    PIXI.Assets.get("diamondgreen"),
    containers.pyramidContainer
  )
  gameAssets.diamond.position.set(
    0,
    -gameAssets.pyramid.height * 0.5 + gameAssets.diamond.height * 0.6
  )


}

function createPopup () {
  containers.popupContainer = new PIXI.Container()
  containers.rootContainer.addChild(containers.popupContainer)
  gameAssets.winPopup = new PIXI.Sprite()
  gameAssets.winPopup = spriteSettings(gameAssets.winPopup, PIXI.Assets.get('winningPopup'), containers.popupContainer)
  containers.popupContainer.zIndex = 1
  let youwonText = new PIXI.Text('YOU WON', gameAssets.styleNormal)
  let richText = new PIXI.Text('', gameAssets.styleGradient)
  let normalText = new PIXI.Text('', gameAssets.styleNormal)

  youwonText.anchor.set(0.5)
  richText.anchor.set(0.5)
  normalText.anchor.set(0.5)

  let multiplier = 1.5
  if (isDevice() == 'PC') {
    multiplier = 1.5
  } else {
    multiplier = 2
  }

  youwonText.scale.set((multiplier - 0.4) * appAspectRatioX)
  richText.scale.set((multiplier - 0.2) * appAspectRatioX)
  normalText.scale.set((multiplier - 0.4) * appAspectRatioX)

  gameAssets.winPopup.addChild(youwonText)
  gameAssets.winPopup.addChild(richText)
  gameAssets.winPopup.addChild(normalText)


  containers.popupContainer.scale.set(appAspectRatioX * multiplier)
  containers.popupContainer.doNothing = () => { }
  let time = 2 * 60
  containers.popupContainer.ActivePopup = (delta) => {
    time -= delta
    if (time < 0) {
      containers.popupContainer.visible = false
      time = 2 * 60
      containers.popupContainer.currentState = containers.popupContainer.doNothing
    }
  }
  containers.popupContainer.currentState = containers.popupContainer.doNothing

  // gameAssets.winPopup.position.set(gameAssets.winPopup.width * 0.05, 0)

  containers.popupContainer.visible = false
  winningPopup = containers.popupContainer


}

function setWinAmount (str1, str2) {
  containers.popupContainer.visible = false

  gameAssets.winPopup.children[1].text = str1
  gameAssets.winPopup.children[2].text = str2

  // containers.popupContainer.position.x = containers.pyramidContainer.x
  if (isDevice() == 'PC')
    containers.popupContainer.position.set(-(appWidth - containers.pyramidContainer.width) / 2.5, 0)
  else
    containers.popupContainer.position.set(0, -appHeight * 0.5 + containers.pyramidContainer.height * 0.5)

  gameAssets.winPopup.children[0].position.y = -gameAssets.winPopup.children[0].height * 1.5
  gameAssets.winPopup.children[1].position.y = -gameAssets.winPopup.children[1].height * 0.1
  gameAssets.winPopup.children[2].position.y = gameAssets.winPopup.children[2].height * 1.3

}

export function displayWinAmount () {
  containers.popupContainer.visible = true
  containers.popupContainer.currentState = containers.popupContainer.ActivePopup
}

export function setupPyramid () {
  gameAssets.diamond.texture = PIXI.Assets.get(selectedmodes.diamond)

  if (isDevice() == 'PC') {
    containers.pyramidContainer.scale.set(1.67 * appAspectRatioX) //1.67
    if (appHeight < containers.pyramidContainer.height) {
      containers.pyramidContainer.scale.set(1.67 * appAspectRatioY) //1.67
    }
    containers.pyramidContainer.position.set(-(appWidth - containers.pyramidContainer.width) / 2.5, 0)
    pegHolder.x = gameDisplaySettings.pc.x
    pegHolder.y = gameDisplaySettings.pc.y
    pegHolder.scale.set((appAspectRatioX / (3 - (0.1 * (totalLines - 9)))))
    pegHolder.position.set(containers.pyramidContainer.x, containers.pyramidContainer.y + containers.pyramidContainer.height * 0.07)
  } else {
    containers.pyramidContainer.scale.set(3 * appAspectRatioX) //1.5
    containers.pyramidContainer.position.set(0, -appHeight * 0.5 + containers.pyramidContainer.height * 0.5)
    pegHolder.x = gameDisplaySettings.mobile.x
    pegHolder.y = gameDisplaySettings.mobile.y
    pegHolder.scale.set(appAspectRatioX * 0.6)
    pegHolder.position.set(containers.pyramidContainer.x, containers.pyramidContainer.y - containers.pyramidContainer.height * 0.03)
  }

}

function createPegs (pegConfig) {
  let pegNo = 0
  for (let row = 0; row < totalLines; row++) {
    const numPegs = pegsInRows[row]
    const singleRowPegsContainer = new PIXI.Container()
    let currentPeg = []
    for (let i = 0; i < numPegs; i++) {
      const peg = {}

      peg.sprite = new PIXI.Sprite(PIXI.Assets.get("pin"))
      peg.sprite.width = config.pegs.pegsWidth + (16 - totalLines) * 3
      peg.sprite.height = config.pegs.pegsWidth + (16 - totalLines) * 3
      peg.sprite.anchor.set(0.5)
      peg.sprite.x =
        (i - (numPegs - 1) / 2) *
        ((config.pegs.spacing.width * 16) / totalLines)
      if (row == 0)
        peg.sprite.y =
          row * ((config.pegs.spacing.height * 16) / totalLines) -
          config.topMargin
      else
        peg.sprite.y =
          (row * (config.pegs.spacing.height - totalLines * 0.45) * 16) /
          totalLines -
          config.topMargin

      pegNo++
      peg.shine = new PIXI.Sprite()
      peg.shine = spriteSettings(
        peg.shine,
        PIXI.Assets.get("shine"),
        peg.sprite
      )
      peg.shine.visible = false
      peg.shine.position.set(-2, 0.9)
      peg.shine.scale.set(0.9)
      peg.doNothing = () => { }
      peg.shineEffect = (delta) => {
        if (!peg.isOn) return
        peg.count++

        const mileStoneOne = 8 / config.ball.animationSpeed,
          mileStoneTwo = mileStoneOne + 8 / config.ball.animationSpeed,
          mileStoneThree = mileStoneTwo + 5 / config.ball.animationSpeed

        if (peg.count < mileStoneOne) {
          peg.shine.alpha = 0.8
        } else if (peg.count >= mileStoneOne && peg.count < mileStoneTwo) {
          peg.shine.alpha = 0.8 //this should be 1
        } else if (peg.count >= mileStoneTwo && peg.count < mileStoneThree) {
          peg.shineState = peg.doNothing
          peg.shine.alpha = 0
          peg.isOn = false
        } else {
          peg.shineState = peg.doNothing
          peg.shine.alpha = 0
          peg.isOn = false
        }
      }
      peg.startShine = () => {
        peg.shine.visible = true
        peg.count = 0
        peg.isOn = true
        peg.shineState = peg.shineEffect
        playDropTick()
      }
      peg.shineState = peg.doNothing
      peg.endShine = () => {
        peg.shine.visible = false
      }

      singleRowPegsContainer.addChild(peg.sprite)
      pegs.push(peg)
      currentPeg.push(peg)
    }
    pegsForShine.push(currentPeg)
    currentPeg = []
    pegHolder.addChild(singleRowPegsContainer)
  }
}

// let ballScaleForAnimation = 1.9

function createBallsPool () {
  ballPool = new ObjectPool(() => new AnimationSprite(pegHolder, pegs[0].sprite.y), 50)
}

function animatedBallCreate (testPathAnim) {
  let animatedBallSprite = ballPool.getObject()
  animatedBallSprite.setVisiblity(true)
  animatedBallSprite.animationSprite.scale.set(
    getDirection(testPathAnim, 0) * config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale,
    config.ball.scaleForAnimation[totalLines - 8] * config.ball.ballScale
  )
  animatedBallSprite.animationSprite.drop = animatedBallSprite.moveBall
  animatedBallSprite.animationSprite.onLoop = () => animatedBallSprite.onLoop(testPathAnim)

  balls.push(animatedBallSprite.animationSprite)

  playFirstDrop()
}

function createBall () {
  totalBalls++
  animatedBallCreate(path[totalBalls - 1])
}

function setupBoxes () {
  const boxScale = 1.2

  let boxHeight = config.box.height - totalLines * config.box.heightScale //75;
  let boxWidth = config.box.width - totalLines * config.box.widthScale

  for (let box = 0; box < pegsInRows[totalLines - 1] - 1; box++) {
    let sprite = new PIXI.Graphics()
    pegHolder.addChild(sprite)
    sprite.lineStyle(0, 0xFF00FF, 1)
    sprite.beginFill(0xFFFFFF, 1)
    sprite.drawRoundedRect(-boxWidth / 2, -boxHeight / 2, boxWidth, boxHeight, 20)
    sprite.endFill()

    sprite.scale.set(boxScale)
    sprite.x =
      (box - (pegsInRows[totalLines - 1] - 2) / 2) *
      ((config.pegs.spacing.width * 16) / totalLines)
    sprite.y = sprite.startPos = config.topMargin
    boxes.push({
      sprite: sprite
    })
    sprite.boxColor = box.white
    sprite.boxState = sprite.doNothing
    sprite.BoxBounceEffect = (delta) => {
      if (sprite.y > sprite.startPos)
        sprite.y -= delta * config.box.boxSpeed
      else {
        sprite.y = sprite.startPos
        sprite.boxState = sprite.doNothing
      }
    }



    let text = new PIXI.Text(
      "",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        // fontSize: totalLines > 12 ? 33 : 45,
        fontSize: 50,
        fontWeight: "bold",
      })
    )
    text.anchor.set(0.5)
    let fontscale = totalLines > 12 ? 0.7 : 1
    text.scale.set(fontscale)
    sprite.addChild(text)

    if (box < boxNumbers.Red || box == pegsInRows[totalLines - 1] - 1 - boxNumbers.Red)
      sprite.tint = boxColor.red
    else if (box < boxNumbers.Orange || box == pegsInRows[totalLines - 1] - 1 - boxNumbers.Orange)
      sprite.tint = boxColor.orange
    else if (box < boxNumbers.Pink || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Pink)
      sprite.tint = boxColor.pink
    else if (box < boxNumbers.Purple || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Purple)
      sprite.tint = boxColor.purple
    else if (box < boxNumbers.Blue || box >= pegsInRows[totalLines - 1] - 1 - boxNumbers.Blue)
      sprite.tint = boxColor.blue
    else
      sprite.tint = boxColor.green

    sprite.boxColor = sprite.tint
  }
  isSetupBoxes = true

}

function setupPayoutText (payout) {
  for (let i = 0; i < boxes.length; i++) {
    if (payout[i] || payout[i] === 0) {
      boxes[i].sprite.children[0].text = payout[i] + 'x'
      if (payout[i] > 999)
        boxes[i].sprite.children[0].text = payout[i] / 1000 + 'k x'
    } else console.error("Sent payout is not a valid number")
  }
}

function startMovingBall (ballPath) {
  path.push([...ballPath])
}

function changeNumberOfLines (reqLines) {
  isSetupBoxes = false
  totalLines = reqLines

  function destroyBgContianer () {
    pegHolder.destroy({
      children: true,
    })
  }

  function createpinsContainer () {
    resetUI()
  }

  function resetValues () {
    containers.ballContainer = []
    pegs = []
    boxes = []
    pegsForShine = []
    pegsInRows = Array.from({
      length: totalLines
    },
      (_, rowIndex) => rowIndex + config.pinInFirstLine
    )
    gameAssets.pyramid = new PIXI.Sprite()
    gameAssets.diamond = new PIXI.Sprite()
  }
  bgDestroyed = true
  destroyBgContianer()
  resetValues()
  createpinsContainer()
}

function clearAllBalls () {
  totalBalls = 0
  containers.ballContainer.forEach((ballContainer) => {
    ballContainer.destroyBall()
  })

  path = []
}

function scaleFactor () {
  return (config.maxLines + 16) / (totalLines + 16)
}

function gameScaling () {
  let rhs = 1.4 - Math.floor((totalLines - 8) / 2) * 0.1
  rhs = rhs.toFixed(1)
  containers.rootContainer.scale.set(scaleFactor() * rhs)
}

function boxBounceEffect (index) {
  const box = boxes[index]

  if (!box) return
  box.sprite.y += 40 // box.sprite.startPos;
  box.sprite.boxBounceEffectState = box.sprite.BoxBounceEffect
}

function lightningModeUI (lightData) {
}

function removeLightningMode () {
}

function normalModeUI () {
  pegs.forEach((p) => {
    p.sprite.tint = boxColor.white
  })
}

function setupScullBoxes (isScull) {
}


function resetUI () {
  createPegsHolder()
  createPegs(config.pegs)
  setupBoxes()
  createBallsPool()
}

export {
  containers,
  createUI,
  uiCreated,
  createBall,
  startMovingBall,
  changeNumberOfLines,
  clearAllBalls,
  setupPayoutText,
  isSetupBoxes,
  boxBounceEffect,
  boolBallCreated,
  totalBalls,
  balls,
  pegs,
  boxes,
  removeLightningMode,
  normalModeUI,
  lightningModeUI,
  gameAssets,
  ballPool,
  pegsForShine,
  totalLines,
  setWinAmount,
  winningPopup
}

isPixiStandAlone && divButtons()

function divButtons () {
  const createBallDiv = document.createElement("button")
  createBallDiv.innerHTML = "createBall"
  createBallDiv.onclick = () => createBall()
  document.body.appendChild(createBallDiv)

  const boxBounceDiv = document.createElement("button")
  boxBounceDiv.innerHTML = "boxBounce"
  boxBounceDiv.onclick = () =>
    boxBounceEffect(parseInt(prompt("Enter number of boxBounce")))
  document.body.appendChild(boxBounceDiv)

  const linesDiv = document.createElement("button")
  linesDiv.innerHTML = "lines"
  linesDiv.onclick = () =>
    changeNumberOfLines(parseInt(prompt("Enter number of lines")))
  document.body.appendChild(linesDiv)

  const lightningModeDiv = document.createElement("button")
  lightningModeDiv.innerHTML = "lightningMode"
  lightningModeDiv.onclick = () => pixiLightningMode()
  document.body.appendChild(lightningModeDiv)

  const normalModeDiv = document.createElement("button")
  normalModeDiv.innerHTML = "normalMode"
  normalModeDiv.onclick = () => removeLightningMode()
  document.body.appendChild(normalModeDiv)

  const clearAllBallDiv = document.createElement("button")
  clearAllBallDiv.innerHTML = "clearAllBall"
  clearAllBallDiv.onclick = () => clearAllBalls()
  document.body.appendChild(clearAllBallDiv)

  const ballPathOneDiv = document.createElement("button")
  ballPathOneDiv.innerHTML = "ballPathOne"
  ballPathOneDiv.onclick = () => startMovingBall(testPath)
  document.body.appendChild(ballPathOneDiv)

  const ballPathTwoDiv = document.createElement("button")
  ballPathTwoDiv.innerHTML = "ballPathTwo"
  ballPathTwoDiv.onclick = () => startMovingBall(testPath1)
  document.body.appendChild(ballPathTwoDiv)

  const ballPathThreeDiv = document.createElement("button")
  ballPathThreeDiv.innerHTML = "ballPathThree"
  ballPathThreeDiv.onclick = () => startMovingBall(testPath2)
  document.body.appendChild(ballPathThreeDiv)

  const ballPathFourDiv = document.createElement("button")
  ballPathFourDiv.innerHTML = "ballPathFour"
  ballPathFourDiv.onclick = () => startMovingBall(testPath3)
  document.body.appendChild(ballPathFourDiv)
}
