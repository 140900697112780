import store from "redux-store/store"
import { setSliderData } from "../../pages/SpaceDiceGame/slice-thunk/spaceDiceGame.slice"
const insideReact = true
const {
  PIXI
} = window

let uiCreated = false

const appWidth = 800
const appHeight = 450

let dragPointer = null

const minHeight = -34
const maxHeight = -14
const leftStartPoint = 2500 /* -250 */
const rightStartPoint = 7500 /* 250 */
const leftEdge = 70.6
const rightEdge = 729.05
const overallValue = 10000
const maxSliderWidth = 9998
const diceHeightOffset = -38
const diceScale = 0.4
const diceWhiteLineOffsetY = 70

let scaleCircles = 0.7

// -330  330  15.15 local values
//   60  740    global values

let promiseAssets

const backgroundAlpha = insideReact ? 0 : 1

const app = new PIXI.Application({
  width: appWidth,
  height: appHeight,
  antialias: true,
  backgroundAlpha: backgroundAlpha
})

appTransform(1280, 720)

function appTransform (x, y) {
  app.view.style.width = x + 'px'
  app.view.style.height = y + 'px'
}

const appAspectRationX = appWidth / 800

scaleCircles *= appAspectRationX

async function loadAssets () {
  PIXI.Assets.addBundle('Images', {
    sliderAssets: '/pixi/space-dice-game/sliderAssets.json'
  })
  promiseAssets = await PIXI.Assets.loadBundle('Images')
}

const containers = {
  rootContainer: new PIXI.Container(),
  rearlineContainer: new PIXI.Container(),
  maskContainer: new PIXI.Container(),
  diceContainer: new PIXI.Container(),
  diceWhiteLineContainer: new PIXI.Container()
}
const gameAssets = {
  rightArrow: new PIXI.Sprite(),
  leftArrow: new PIXI.Sprite(),
  centerPoint: new PIXI.Sprite(),
  frontBar: new PIXI.Sprite(),
  backContainer: new PIXI.Sprite(),
  line: new PIXI.Graphics(),
  copyline: new PIXI.Graphics(),
  dice: new PIXI.Sprite(),
  diceWhiteLine: new PIXI.Graphics()
}

export const startSlider = () => {
  document.getElementById('pixi-space-dice-game').appendChild(app.view)
  if (!insideReact) globalThis.__PIXI_APP__ = app

  loadAssets()
    .then(function () {
      onAssetsLoaded()
    })
    .catch(function (err) {
      console.error(err)
    })
}

if (!insideReact) startSlider()

function onAssetsLoaded () {
  createUI()
}

function createUI () {
  if (!uiCreated) {
    setupContainer()
    setupUI()
    uiCreated = true
  }
  setValuesToInitial()
}

function setupContainer () {
  app.stage.addChild(containers.rearlineContainer)
  containers.rearlineContainer.pivot.x = containers.rearlineContainer.width / 2
  containers.rearlineContainer.pivot.y = containers.rearlineContainer.height / 2
  containers.rearlineContainer.position.set(appWidth / 2, appHeight / 2)

  app.stage.addChild(containers.diceContainer)

  app.stage.addChild(containers.maskContainer)
  containers.maskContainer.position.set(appWidth / 2, appHeight / 2)

  containers.diceContainer.position.set(containers.maskContainer.position.x, containers.maskContainer.position.y)

  app.stage.addChild(containers.diceWhiteLineContainer)
  containers.diceWhiteLineContainer.position.set(containers.maskContainer.position.x, containers.maskContainer.position.y)
}

let maskCalPosition, dragPointerCalPosition

function setupUI () {
  gameAssets.backContainer = createSprite(gameAssets.backContainer, PIXI.Assets.get('sliderAssets').textures
    .set, containers.rearlineContainer)
  gameAssets.backContainer.scale.set(0.7 * appAspectRationX)
  gameAssets.backContainer.position.set(0, 0)

  gameAssets.frontBar = createSprite(gameAssets.frontBar, PIXI.Assets.get('sliderAssets').textures
    .frontbar, containers.rearlineContainer)
  gameAssets.frontBar.scale.set(0.7 * appAspectRationX)
  gameAssets.frontBar.position.set(0, -27)
  gameAssets.centerPoint = createSprite(gameAssets.centerPoint, PIXI.Assets.get('sliderAssets').textures
    .centerbtn, containers.maskContainer)

  gameAssets.centerPoint.scale.set(scaleCircles)
  gameAssets.centerPoint.position.set(0, heightCalculate(0))
  containers.maskContainer.addChild(gameAssets.line)
  containers.maskContainer.addChild(gameAssets.copyline)

  gameAssets.rightArrow = createSprite(gameAssets.rightArrow, PIXI.Assets.get('sliderAssets').textures
    .rightbtn, containers.maskContainer)
  gameAssets.rightArrow.scale.set(scaleCircles)
  gameAssets.rightArrow.position.set(positionFromValue(rightStartPoint), heightCalculate(positionFromValue(rightStartPoint)))

  gameAssets.leftArrow = createSprite(gameAssets.leftArrow, PIXI.Assets.get('sliderAssets').textures
    .leftbtn, containers.maskContainer)
  gameAssets.leftArrow.scale.set(scaleCircles)
  gameAssets.leftArrow.position.set(positionFromValue(leftStartPoint), heightCalculate(positionFromValue(leftStartPoint)))

  gameAssets.line.beginFill(0xFF3300)
  gameAssets.line.lineStyle(10, 0x00ff00, 0.001)
  gameAssets.line.moveTo(gameAssets.leftArrow.position.x, gameAssets.leftArrow.position
    .y - 30)
  gameAssets.line.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y - 30)
  gameAssets.line.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.line.lineTo(gameAssets.leftArrow.position.x, gameAssets.rightArrow.position
    .y + 30)
  gameAssets.line.closePath()

  gameAssets.copyline.beginFill(0xFF3300, 0.001)
  gameAssets.copyline.lineStyle(10, 0x00ff00, 0.001)
  gameAssets.copyline.moveTo(gameAssets.leftArrow.position.x, gameAssets.leftArrow
    .position.y - 30)
  gameAssets.copyline.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y - 30)
  gameAssets.copyline.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.copyline.lineTo(gameAssets.leftArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.copyline.closePath()

  gameAssets.frontBar.mask = gameAssets.line

  app.stage.interactive = true
  app.stage.hitArea = app.screen
  app.stage.on('pointerup', onDragEnd)
  app.stage.on('pointerupoutside', onDragEnd)

  gameAssets.rightArrow.interactive = true
  gameAssets.rightArrow.cursor = 'pointer'
  gameAssets.rightArrow.on('pointerdown', onDragStart)

  gameAssets.leftArrow.interactive = true
  gameAssets.leftArrow.cursor = 'pointer'
  gameAssets.leftArrow.on('pointerdown', onDragStart)

  gameAssets.copyline.interactive = true
  gameAssets.copyline.cursor = 'pointer'
  gameAssets.copyline.on('pointerdown', onDragStart)

  gameAssets.dice = createSprite(gameAssets.dice, PIXI.Assets.get('sliderAssets').textures.dice, containers.diceContainer)
  gameAssets.dice.scale.set(diceScale)
  gameAssets.dice.anchor.set(0.5)
  gameAssets.dice.position.set(0, heightCalculate(0) + diceHeightOffset)
  gameAssets.dice.targetPos = {
    x: 0,
    y: heightCalculate(0) + diceHeightOffset
  }

  gameAssets.diceWhiteLine.beginFill(0xffffff)
  gameAssets.diceWhiteLine.drawRect(0, -42, 2, 18) // ;(-10, 0, 100, 100)
  containers.diceWhiteLineContainer.addChild(gameAssets.diceWhiteLine)
}

function setValuesToInitial () {
  setManualPositions(2500.5, 7500)
  const xPos = positionFromValueIsolate(5000)
  const yPos = heightCalculate(xPos) + diceHeightOffset

  gameAssets.dice.position.set(xPos, yPos)
  gameAssets.dice.targetPos = {
    x: xPos,
    y: yPos
  }
}

let dif = 0
let containerDif = 0

const tempGraphicposition = 0
let tempGraphicwidth = 0

let leftpointer = 0
let rightpointer = 0
let currentpointer = 0

function onDragStart (event) {
  dragPointer = this

  tempGraphicwidth = (gameAssets.rightArrow.getGlobalPosition().x - gameAssets.leftArrow.getGlobalPosition().x) / 2
  if (dragPointer === gameAssets.copyline) {
    dif = event.global.x - ((gameAssets.leftArrow.getGlobalPosition().x) + tempGraphicwidth)
    containerDif = containers.maskContainer.position.x - ((gameAssets.leftArrow.getGlobalPosition().x) + tempGraphicwidth)
  } else {
    dif = containers.maskContainer.toLocal(event.global).x - this.position.x
  }

  app.stage.on('pointermove', onDragMove)
}

let temp

function onDragMove (event) {
  if (dragPointer) {
    switch (dragPointer) {
      case gameAssets.leftArrow:
        leftpointer = Math.max(containers.maskContainer.toLocal(gameAssets.frontBar.getGlobalPosition()).x - gameAssets.frontBar.width / 2 + gameAssets.leftArrow.width / 2,
          containers.maskContainer.toLocal(gameAssets.rightArrow.getGlobalPosition()).x - sliderWidthCalculator() - 0.5)
        rightpointer = containers.maskContainer.toLocal(gameAssets.rightArrow.getGlobalPosition()).x
        currentpointer = function () {
          return containers.maskContainer.toLocal(event.global).x - dif
        }

        dragPointer.position.x = Math.max(leftpointer, Math.min(rightpointer, currentpointer()))
        dragPointer.position.y = heightCalculate(dragPointer.getGlobalPosition().x - appWidth / 2)
        break

      case gameAssets.rightArrow:

        rightpointer = Math.min(containers.maskContainer.toLocal(gameAssets.frontBar.getGlobalPosition()).x + gameAssets.frontBar.width / 2 - gameAssets.rightArrow.width / 2 + 0.05,
          containers.maskContainer.toLocal(gameAssets.leftArrow.getGlobalPosition()).x + sliderWidthCalculator() + 0.5)
        leftpointer = containers.maskContainer.toLocal(gameAssets.leftArrow.getGlobalPosition()).x
        currentpointer = function () {
          return containers.maskContainer.toLocal(event.global).x - dif
        }
        dragPointer.position.x = Math.max(leftpointer, Math.min(rightpointer, currentpointer()))
        dragPointer.position.y = heightCalculate(dragPointer.getGlobalPosition().x - appWidth / 2)
        break

      case gameAssets.copyline:
        containers.maskContainer.position.x =
          Math.max((gameAssets.frontBar.getGlobalPosition().x - gameAssets.frontBar.width / 2 + tempGraphicwidth + containerDif + gameAssets.leftArrow.width / 2),
            Math.min((gameAssets.frontBar.getGlobalPosition().x + gameAssets.frontBar.width / 2 - tempGraphicwidth + containerDif - gameAssets.rightArrow.width / 2),
              (event.global.x + containerDif - dif)))

        gameAssets.leftArrow.position.y = heightCalculate(gameAssets.leftArrow.getGlobalPosition().x - appWidth / 2)
        gameAssets.rightArrow.position.y = heightCalculate(gameAssets.rightArrow.getGlobalPosition().x - appWidth / 2)
        gameAssets.centerPoint.position.y = heightCalculate(gameAssets.centerPoint.getGlobalPosition().x - appWidth / 2)
        break
    }

    backGreenSection()

    centerPointPositionSet()

    reduxValuesSet()
  }
}

function backGreenSection () {
  gameAssets.line.clear()
  gameAssets.line.beginFill(0xFF3300)
  gameAssets.line.lineStyle(10, 0x00ff00, 0)
  gameAssets.line.moveTo(gameAssets.leftArrow.position.x, gameAssets.leftArrow
    .position.y - 30)
  gameAssets.line.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y - 30)
  gameAssets.line.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.line.lineTo(gameAssets.leftArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.line.closePath()

  gameAssets.copyline.clear()
  gameAssets.copyline.beginFill(0xFF3300, 0.001)
  gameAssets.copyline.lineStyle(10, 0x00ff00, 0.001)
  gameAssets.copyline.moveTo(gameAssets.leftArrow.position.x, gameAssets.leftArrow
    .position.y - 30)
  gameAssets.copyline.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y - 30)
  gameAssets.copyline.lineTo(gameAssets.rightArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.copyline.lineTo(gameAssets.leftArrow.position.x, gameAssets.rightArrow
    .position.y + 30)
  gameAssets.copyline.closePath()
}

function centerPointPositionSet () {
  gameAssets.centerPoint.position.x = (gameAssets.leftArrow.x + gameAssets.rightArrow.x) / 2
  gameAssets.centerPoint.position.y = heightCalculate(gameAssets.centerPoint.getGlobalPosition().x - appWidth / 2)
}

function onDragEnd () {
  if (dragPointer) {
    app.stage.off('pointermove', onDragMove)
    dragPointer = null
    dif = 0
  }
}

function createSprite (spriteName, textureName, containerName) {
  spriteName.texture = textureName
  containerName.addChild(spriteName)
  spriteName.anchor.set(0.5)
  return spriteName
};

// Convert slider's game with to actual positions
function sliderWidthCalculator () {
  const frontbarCodedwidth = gameAssets.frontBar.width - gameAssets.leftArrow.width
  const codeToGameValueConvertor = overallValue / frontbarCodedwidth

  const internalWidth = maxSliderWidth / codeToGameValueConvertor
  return Math.trunc(internalWidth)
}

function endPointsCheck () {
  if (gameAssets.rightArrow.getGlobalPosition().x + gameAssets.rightArrow.width / 2 < rightEdge &&
    gameAssets.leftArrow.getGlobalPosition().x - gameAssets.leftArrow.width / 2 > leftEdge) { return true } else return false
}

function heightCalculate (width) {
  return (((Math.abs(width) / (500 - 0)) * (maxHeight - minHeight)) + minHeight)
}

// It provide real game values between 0-10000
function valueFromEleCal (element) {
  // return (element.x + valueOffset) * 10
  return (element.getGlobalPosition().x - leftEdge) * (overallValue / (rightEdge - leftEdge))
}

function valueFromPosCal (elementPos) {
  return (overallValue) / 2 + (elementPos) * (overallValue / (rightEdge - leftEdge))
}

// from game value to position of element
function positionFromValue (value) {
  // return ((value * 0.1) - valueOffset)
  return (value * ((rightEdge - leftEdge) / overallValue)) + leftEdge - containers.maskContainer.x
}

function positionFromValueIsolate (value) {
  return (value * ((rightEdge - leftEdge) / overallValue)) + leftEdge - 400
}

export function setManualPositions (leftValue, rightValue) {
  containers.maskContainer.x = app.view.width / 2

  gameAssets.leftArrow.position.x = positionFromValueIsolate(leftValue)
  gameAssets.leftArrow.position.y = heightCalculate(gameAssets.leftArrow.x)

  gameAssets.rightArrow.position.x = positionFromValueIsolate(rightValue)
  gameAssets.rightArrow.position.y = heightCalculate(gameAssets.rightArrow.x)

  centerPointPositionSet()
  backGreenSection()

  reduxValuesSet()
}

function reduxValuesSet () {
  let left = Math.floor(valueFromEleCal(gameAssets.leftArrow))
  const center = Math.floor(valueFromEleCal(gameAssets.centerPoint))
  let right = Math.floor(valueFromEleCal(gameAssets.rightArrow))

  left = left < 0 ? 0 : left
  right = right > 9999 ? 9999 : right

  if (insideReact) {
    store.dispatch(setSliderData({
      left,
      center,
      right
    }))
  } else {
  }
}

export function moveDiceTarget (value) {
  const x = positionFromValueIsolate(value)
  const y = heightCalculate(x) + diceHeightOffset

  // gameAssets.dice.position.set(x, y)
  gameAssets.dice.targetPos = {
    x: x,
    y: y
  }
}

export function barColorRed (turnRed) {
  if (turnRed) {
    gameAssets.backContainer.texture = PIXI.Assets.get('sliderAssets').textures.setRed
    gameAssets.frontBar.texture = PIXI.Assets.get('sliderAssets').textures.rearbar
    gameAssets.centerPoint.texture = PIXI.Assets.get('sliderAssets').textures.centerBtnRed
  } else {
    gameAssets.backContainer.texture = PIXI.Assets.get('sliderAssets').textures.set
    gameAssets.frontBar.texture = PIXI.Assets.get('sliderAssets').textures.frontbar
    gameAssets.centerPoint.texture = PIXI.Assets.get('sliderAssets').textures.centerbtn
  }
}

export function diceRed (turnRed) {
  if (turnRed) gameAssets.dice.texture = PIXI.Assets.get('sliderAssets').textures.diceRed
  else gameAssets.dice.texture = PIXI.Assets.get('sliderAssets').textures.dice
}

function distance (currentPos, targetPos) {
  let distance = Math.pow((targetPos.x - currentPos.x), 2) + Math.pow((targetPos.y - currentPos.y), 2)
  distance = Math.sqrt(distance)
  return distance
}

function moveTowards (currentPos, targetPos, speed) {
  const xDirection = targetPos.x - currentPos.x
  const yDirection = targetPos.y - currentPos.y
  currentPos.x += (xDirection * speed)
  currentPos.y += (yDirection * speed)
  return currentPos
}

app.ticker.add((delta) => {
  if (!uiCreated) return

  if (distance(gameAssets.dice.position, gameAssets.dice.targetPos) > 0.5) {
    gameAssets.dice.position = moveTowards(gameAssets.dice.position, gameAssets.dice.targetPos, (0.1 * delta))
    gameAssets.diceWhiteLine.x = gameAssets.dice.x
    gameAssets.diceWhiteLine.y = gameAssets.dice.y + diceWhiteLineOffsetY
  }
})
